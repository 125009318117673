import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import aw_logo from "../images/aw_logo.png";

// Define constants outside the component
const FOOTER_BACKGROUND = "#eeebe5";
const FOOTER_TEXT = `Apply Within is the radio outreach ministry of North Country Chapel. 
We desire to reach out locally, nationally and internationally with the Word of God 
in fulfillment of the Great Commission, "and ye shall be witnesses unto me both in 
Jerusalem, and in all Judea, and in Samaria, and unto the uttermost part of the earth".`;

function Footer() {
  const currentYear = new Date().getFullYear();
  
  return (
    <footer>
      <Container fluid style={{ backgroundColor: FOOTER_BACKGROUND, padding: "2rem 0" }}>
        <Container>
          <Row className="justify-content-center">
            <Col xs={12} md={8} lg={6}>
              <div className="text-center">
                <img 
                  src={aw_logo} 
                  alt="Apply Within logo" 
                  className="img-fluid mb-3" 
                  style={{ maxWidth: "400px" }}
                />
                <p className="mb-4">{FOOTER_TEXT}</p>
                <p className="text-muted">Copyright © {currentYear} Apply Within Radio</p>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </footer>
  );
}

export default Footer;