import React from "react";

function Header() {
  return (
    <header>
      <nav className="top-bar" style={{
        backgroundColor: "#eeebe5",
        padding: "0.5rem 3%",
        textAlign: "right"
      }}>
        <a 
          href="https://northcountrychapel.com" 
          target="_blank"
          rel="noopener noreferrer"
          style={{
            color: "#333",
            textDecoration: "none",
            fontWeight: "500"
          }}
        >
          northcountrychapel.com
        </a>
      </nav>
    </header>
  );
}

export default Header;