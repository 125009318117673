import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Spinner } from "react-bootstrap";
import Header from "./Header";
import NavBar from "./NavBar";
import Footer from "./Footer";

// Lazy load components for better performance
const Program = lazy(() => import("./Programs"));
const Stations = lazy(() => import("./Stations"));

// Loading fallback component
const LoadingSpinner = () => (
  <div className="text-center spinner" style={{ padding: "2rem 0" }}>
    <Spinner
      as="span"
      variant="secondary"
      size="lg"
      role="status"
      aria-hidden="true"
      animation="border"
    />
    <p>Loading page...</p>
  </div>
);

function App() {
  return (
    <Router>
      <div className="d-flex flex-column min-vh-100">
        <Header />
        <NavBar />
        <main className="flex-grow-1">
          <Suspense fallback={<LoadingSpinner />}>
            <Routes>
              <Route path="/" element={<Program />} />
              <Route path="/stations" element={<Stations />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

// Simple 404 page
const NotFound = () => (
  <div className="text-center my-5">
    <h2>Page Not Found</h2>
    <p>The page you're looking for doesn't exist or has been moved.</p>
  </div>
);

export default App;