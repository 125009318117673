import React from "react";
import { Navbar, Nav, Container } from "react-bootstrap";
import aw_logo from "../images/aw_logo.png";

const NavBar = () => {
  return (
    <Navbar bg="light" expand="md" className="py-0">
      <Container>
        <Navbar.Brand href="/">
          <img 
            src={aw_logo} 
            alt="Apply Within Radio" 
            className="navbar-logo" 
            style={{ maxHeight: "50px" }}
          />
        </Navbar.Brand>
        
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/" className="mx-2">Home</Nav.Link>
            <Nav.Link href="/stations" className="mx-2">Stations</Nav.Link>
            {/* <Nav.Link href="/programs" className="mx-2">Programs</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;